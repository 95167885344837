<template>
    <div>
  
      <div class="card" style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title">함께 기도해요!!</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;"><button type="button"  class="btn btn-primary btn-sm"  @click="writeBoard">기도 등록</button></h6>
          <div class="col-3">
  
          </div>      
          
          <div v-for="item in items" :key="item.id">

            <div class="card border-secondary mb-3" style="max-width: 100%;">
                <div class="card-header" >
                  <div class="row">
                    <div class="col">
                      <span @click="detailView(item)">{{ item.writer }} / {{ formatDate(item.wdate) }}</span>
                    </div>
                    <div class="col" style="text-align: right;">
                      <span class="image-container"><img src="../assets/heart.png"  alt="..."  :style="imageStyle" @click="iread(item,'1')" >&nbsp;<span :id="'hitscnt'+item.no">{{ item.hits }}</span></span>
                    </div>
                  </div>
                </div>
                <div class="card-body text-secondary">
                  <h5 class="card-title">{{ item.subject }} </h5>
                  <p class="card-text" v-html="getContent(item.contents)"></p>
                </div>
              </div>

        </div>
    
        <div v-if="loading" class="loading">
            Loading...
        </div>

            <!-- <p class="card-text">
            <table class="table">
                <tbody>
                  <tr v-for="(list, idx) in nlists" v-bind:key="list.no" @click="detailView(list)">
                    <th scope="row">{{idx + 1}}</th>
                    <td>{{ list.subject.substring(0,30) }}</td>
                    <td>{{ formatDate(list.wdate) }}</td>
                  </tr>
  
                </tbody>
              </table>
  
          </p> -->
        </div>
      </div>
      <p></p>
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">중보기도 등록</h5>      
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <p></p>
          <div class="form-group mx-sm-3 mb-2" v-if="isModify">
            <div class="row" style="align-items: baseline;">
              <div class="col-2" style="text-align: right; padding-right: 0px;">
                제목
              </div>
              <div class="col-10">                     
                  <input
                    type="text"
                    class="form-control"
                    id="formschool"
                    name="formschool"
                    v-model="form.subject"
                    autocomplete="off"
                    placeholder=""
                    style="width: 90%;"
                  />
                </div>
              </div>            
            </div>  
  
            <div class="form-group mx-sm-3 mb-2" v-else>
              <div class="row" style="align-items: baseline;">
                <div class="col-2" style="text-align: right; padding-right: 0px;">
                  제목
                </div>
                <div class="col-10" v-html="getContent(form.subject)">                     
  
                  </div>
                </div>            
              </div>  
  
            <div class="form-group mx-sm-3 mb-2" v-if="isModify">
              <div class="row" style="align-items: baseline;">
                <div class="col-2" style="text-align: right; padding-right: 0px;">
                  내용
                </div>
                <div class="col-10">                                     
                  <textarea
                      type="text"
                      class="form-control"
                      id="formrmk"
                      name="formrmk"
                      v-model="form.contents"
                      autocomplete="off"
                      placeholder=""
                      rows="14"
                      style="width: 90%;"
                    ></textarea>
                  </div>
                </div>            
              </div>      
              
              <div class="form-group mx-sm-3 mb-2" v-else>
                <div class="row" style="align-items: baseline;">
                  <div class="col-2" style="text-align: right; padding-right: 0px;" >
                    내용
                  </div>
                  <div class="col-10" v-html="getContent(form.contents)">                                                                         
                    </div>
                  </div>            
                </div>   
  
          <div class="modal-footer">                  
            <button type="button" class="btn btn-primary" @click="changeMode" v-show="!isModify">수정</button>
            <button type="button" class="btn btn-warning" @click="writeBrd" v-show="isModify">저장</button>
            <button type="button" class="btn btn-warning" @click="deleteBrd" v-show="!isModify">삭제</button>          
            <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
          </div>
        </div>
      </div>
      </div>
  
  
    </div>
  </template>
  
  <script>
    import manageService from "../services/manageService";
    import dayjs from 'dayjs';
    import $ from "jquery";
  
    export default {
        component: {
            dayjs
        },        
      data() {
        return {

          nlists: {},
          slists: {},   
          isNew : false,    
          items: [],
          loading: false,
          page: 1,
          limit: 5,  // 원하는 페이지당 항목 수

          form : {
            subject : "",
            contents: "",
            writer : this.$store.state.name,
            no : 0,
            category: "3",
          },
          id: this.$store.state.id,
          grade: this.$store.state.grade,
          phone: this.$store.state.phone,
          name: this.$store.state.name,
          teacherid: this.$store.state.teacherid,
          role: this.$store.state.role,
          token: this.$store.state.token,     
          classid: this.$store.state.classid,  
          isModify : false,  
          imageWidth: 20, 
          readcount: 0,
        }
      },
      computed:{
        formatDate(){
            return (v)=>{
              return dayjs(v).format("MM.DD");
              }
           },       
           imageStyle() {
              return {
                width: `${this.imageWidth}px`,
                border: 'none',
              }
            },                     
     },          
      methods:{
        iread(iitem, param){
          var iid = 'hitscnt'+iitem.no;
          var element = document.getElementById(iid);
          var hitcnt = element.textContent;

          
          console.log("hitcnt",hitcnt);
          //var hitcnt = document.getElementById(iid).textContent;
          
          var data = {
            classid: this.classid,
            no : iitem.no,
            readyn : param,
          }
          manageService
            .setReadPayCheck(data)
            .then((response) => {
              console.log("response", response);
              this.readyn = true;
              document.getElementById(iid).textContent = parseInt(hitcnt) + 1;
             // this.getTotalAttend();
            })
            .catch((e) => {
            console.log(e);
            });          
        },
        loadMore() {
            this.loading = true;            
            const idata ={
                category: "3",
                classid: this.classid,
                page : this.page,
                limit : this.limit,
              }    
                manageService
                    .getNewsboardall(idata)
                    .then((response) => {
                        if (response.data.length > 0) {
                            this.items.push(...response.data);
                            this.page++;
                            console.log("this.items",this.items);
                        } else {
                            this.reachedEnd = true; // 더 이상 데이터가 없음을 나타내는 플래그
                        }                      
                        this.loading = false;              
                })
                .catch((e) => {
                console.log(e);
                });
        },      
        onScroll() {
        console.log("loading", this.loading);
        console.log("reachedEnd", this.reachedEnd);
        console.log("innerHeight", window.innerHeight + window.scrollY);
        console.log("offsetHeight", document.body.offsetHeight);

            if (!this.loading && !this.reachedEnd && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                this.loadMore();
            }
        },
       
        deleteBrd(){
          const ddata ={
            no : this.form.no,
            category: "3",
            classid: this.classid,
          }
          manageService
              .deleteNewsBoardData(ddata)
              .then((response) => {
                if(response.data[0] === true){
                    this.$toast.show("글이 삭제되었습니다.", {
                          type: "success",
                          position: "top-right",
                        });
                        this.isModify = false;                       
                        $("#exampleModal").modal('hide');                        
                      } else {
                        this.$toast.show("글 삭제에 실패했습니다.", {
                          type: "warning",
                          position: "top-right",
                        });
                      }    
                      this.reachedEnd = false;
                      this.page = 1;
                      this.items = []; // 아이템 리스트를 초기화합니다.   
                      this.loadMore();  
          })
          .catch((e) => {
          console.log(e);
          });
  
        },
        getContent(text){
              if(text){
                  return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
              }
          },    
        changeMode(){
          this.isModify = true;
        },
        writeBrd(){  //글쓰기
          const idata ={
            subject : this.form.subject,
            contents : this.form.contents,
            writer : this.form.writer,
            no : this.form.no,
            category: "3",
            classid: this.classid,
          }

          manageService
              .writerNewsBoardData(idata)
              .then((response) => {  
                if(response.data[0] === true){
                    this.$toast.show("글이 등록되었습니다.", {
                          type: "success",
                          position: "top-right",
                        });
                        this.isModify = false;
                        $("#exampleModal").modal('hide');
                      } else {
                        this.$toast.show("글 등록에 실패했습니다.", {
                          type: "warning",
                          position: "top-right",
                        });
                      }  
                      this.reachedEnd = false;
                      this.page = 1;
                      this.items = []; // 아이템 리스트를 초기화합니다.   
                      this.loadMore();                      
          })
          .catch((e) => {
          console.log(e);
          });
  
        },
            writeBoard(){
              this.isNew = true;
              this.form.subject = "";
              this.form.contents ="";
              this.isModify = true;
              this.form.no = 0;
  
              $("#exampleModal").modal();
            },
            detailView(item){
              this.form = item;
              this.isNew = false;
              this.isModify = false;
  
            $("#exampleModal").modal();
            },
      },   
    mounted() {
        this.loadMore();
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', this.onScroll);
    },
    beforeUnmount() {
        // 스크롤 이벤트 리스너 제거
        window.removeEventListener('scroll', this.onScroll);
    },    
    }

  </script>
  <style scoped>
    .table-dark {
    color: #000;
    background-color: #007bff80;
   }
   .table td, .table th {
      padding: 0.5rem;
  } 
  </style>